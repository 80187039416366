<template>
  <div class="modal-overlay">
    <div class="modal">
      <div class="modal-menu">
        <input type="text" id="sensorSearch" @keyup="filterList"
          placeholder="Search Sensors..." class="modal-search">
        <button class="close" @click="closeMenu"
          @keydown="closeMenu">
          Close
        </button>
      </div>
      <ul id="sensorUL" class="modal-content-container">
        <li
          class="country-container"
          v-for="(country, countryKey) in Object.keys(this.sensorsToShow).sort()"
          :key="countryKey">
          <label class="country-label">{{ country }}</label>
          <div class="country-sets">
            <li v-for="(countrySet, countrySetKey) in sensorsToShow[country]"
              :key="countrySetKey">
              <label class="country-sets-label">
                <input v-model="countrySet.active" type="checkbox"
                  @change="sensorSelection(countrySet.code,
                  countrySet.active, countrySet.countryCode)"/>
                {{ countrySet.name }}
              </label>
            </li>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line */
export default {
  name: 'sensorsItemsMenu',
  data() {
    return {
      sensorSelections: {},
    };
  },

  props: {
    sensorMenuName: [],
    sensorsToShow: {},
  },
  methods: {
    /* Function called when search bar receives input
     * Filters by friendly name for sensor
     */
    filterList() {
      const input = document.getElementById('sensorSearch');
      const searchTerm = input.value.toLowerCase();
      const sensorUL = document.getElementById('sensorUL');
      const countryLIs = sensorUL.getElementsByTagName('li');

      /* Loop through country list items and corresponding sensor items
       * country list not displayed if no sensor matching the search term
       * exists under them
       */
      for (const countryLI of countryLIs) {
        const sensorLIs = countryLI.getElementsByTagName('li');
        let foundItem = false;
        for (const sensorLI of sensorLIs) {
          const sensorLabel = sensorLI.getElementsByTagName('label')[0];
          const labelText = sensorLabel.innerText;
          if (labelText.toLowerCase().indexOf(searchTerm) > -1) {
            countryLI.style.display = '';
            sensorLI.style.display = '';
            foundItem = true;
          } else {
            sensorLI.style.display = 'none';
            if (!foundItem) {
              countryLI.style.display = 'none';
            }
          }
        }
      }
    },

    /* Function activated when a checkbox is selected
     * Uses dictionary so that a queue is not generated if one is selected multiple times
     * Also checks againsts multiple queries so deletions of null & double creations are avoided
     * @param set {String} the set code name
     * @param checked {Boolean} whether it has been selected or deselected
     * @param countrycode {String} the country code of the sensor set
     */
    sensorSelection(set, checked, countrycode) {
      if (this.sensorSelections[set.concat(countrycode)] !== undefined) {
        delete this.sensorSelections[set.concat(countrycode)];
      } else {
        this.sensorSelections[set.concat(countrycode)] = {
          sensor: set,
          check: checked,
          countryCode: countrycode,
        };
      }
    },

    // Closes menu, providing sensors to display, resets selections dict
    closeMenu() {
      this.$emit('close-modal', this.sensorSelections);
      this.sensorSelections = {};
    },
  },
};
</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 40%;
  width: 60%;
  margin-top: 18%;
  margin-bottom: 20%;
  margin-left: 15%;
  padding: 10px 10px;
  border-radius: 20px;
}
.modal-menu {
  display: grid;
  grid-template-columns: 92% 8%;
}
.modal-search {
  color: black;
  border: 2px solid black;
  border-radius: 25px;
  padding: 8px 20px 8px 20px
}
.close {
  cursor: pointer;
  background-color: white;
  color: black;
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.modal-content-container {
  overflow: auto;
  height: 80%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}
.country-container {
  display: inline;
  margin: 10px;
}
.country-label {
  color: black;
  font-weight: bold;
  text-decoration: underline;
  padding: 10px;
}

.country-sets {
  display: grid;
  grid-template-columns: auto;
  text-align: center;
}
.country-sets-label {
  color: black;
  width: 100%;
}

</style>
