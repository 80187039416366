<template>
  <div class="hovering-component">
    <p>{{ heatKeyTitle }}</p>
    <div class="heat-key">
      <p>{{ parseFloat(colourMin).toFixed(2) }}</p>
      <div class="heat-key-gradient"></div>
      <p>{{ parseFloat(colourMax).toFixed(2) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeatKey',
  props: {
    colourMax: Number,
    colourMin: Number,
    heatKeyTitle: String,
  },
};
</script>

<style scoped>
.hovering-component, .heat-key {
  display: flex;
  align-items: center;
}

.hovering-component {
  flex-direction: column;
  padding: 0.5em 0.5em;
}

.hovering-component > p {
  margin: 0 0 0.5em 0;
}

.heat-key > p {
  margin: 0 1em;
}

.heat-key-gradient {
  height: 2em;
  width: 10em;
  background: linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,0,0,1) 100%);
  border-radius: 2px;
}
</style>
