<!-- eslint-disable max-len -->
/* eslint-disable */
<template>
  <div class="hovering-component toolbar-container">
    <div class="burger-menu-container">
      <div class="burger-icon" @click="toggleSideMenu" @keyDown="toggleSideMenu">
        <img :src="burgerImage" @mouseover="burgerImage = burgerImageHover" @focus="burgerImage = burgerImageHover"
          @mouseleave="burgerImage = burgerImageStd" @focusout="burgerImage = burgerImageStd" alt="Burger Icon" />
      </div>
    </div>
    <div class="title-container">
      <h1 class="app-title">SensoriumGIS</h1>
      <h4 class="version-number">v{{ version }}</h4>
    </div>
    <div class="tools">
      <div class="single-tool-container">
        <label class="sensorsButton" @click="toggleSensorDropdown" @keydown="toggleSensorDropdown">
          Sensors
        </label>
      </div>
      <div class="single-tool-container">
        <label class="poiButton" @click="togglePoiDropdown" @keydown="togglePoiDropdown">
          POIs
        </label>
      </div>
      <div class="single-tool-container" id="toolbar-spacer"></div>
      <div class="single-tool-container" id="timebar-toggle-container">
        <label for="toggleTimeBar">Timebar</label>
        <label class="switch">
          <input type="checkbox" id="toggleTimeBar" @change="toggleTimeBar">
          <div class="slider round"></div>
        </label>
      </div>
      <div class="single-tool-container">
        <button type="button" @click="resetViewer" id="reset-button">Reset Viewer</button>
      </div>
      <div class="user-menu-container">
        <div class="user-icon" @click="toggleUserDropdown" @keydown="toggleUserDropdown">
          <img :src="userImage" @mouseover="userImage = userImageHover" @focus="userImage = userImageHover"
            @mouseleave="userImage = userImageStd" @focusout="userImage = userImageStd" alt="User Icon" />
        </div>
      </div>
      <!-- Above tools will be moved to sidebar menu -->
    </div>
    <div class="sensors-dropdown-container" @mouseleave="toggleSensorDropdown" @focusout="toggleSensorDropdown">
      <SensorsDropdown @change="sensorEvent">
      </SensorsDropdown>
    </div>
    <div class="poi-dropdown-container" @mouseleave="togglePoiDropdown" @focusout="togglePoiDropdown">
      <!-- <img
        src="https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/brewery.png"
        height=20px
        width=20px
        alt="Brewery Icon" />
        <br /> -->
      <div class="poi-container">
        <input type="checkbox" id="selectedShowBreweries" v-model="selectedShowBreweries"
          @change="showBreweries($event)" />
        <label for="selectedShowBreweries">Breweries</label>
        <img class="poi-img"
          src="https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/brewery.png"
          alt="Brewery Icon" />
      </div>
      <div class="poi-container">
        <input type="checkbox" id="selectedShowCompanies" v-model="selectedShowCompanies"
          @change="showCompanies($event)" />
        <label for="selectedShowCompanies">Companies</label>
        <img class="poi-img"
          src="https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/Companies-house-icon.png"
          alt="Company Icon" />
      </div>
      <div class="poi-container">
        <input type="checkbox" id="selectedShowPowerstations" v-model="selectedShowPowerstations"
          @change="showPowerstations($event)" />
        <label for="selectedShowPowerstations">Power stations</label>
        <img class="poi-img"
          src="https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/power-station.png"
          alt="Power station Icon" />
      </div>
    </div>
    <div class="user-dropdown-container" @mouseleave="toggleUserDropdown" @focusout="toggleUserDropdown">
      <div class="user-menu-item" @click="signOut" @keyDown="signOut">
        <label>Logout</label>
      </div>
    </div>
    <div class="side-menu-container">
      <!-- <div class="side-menu-tool-container">
        <div class="side-menu-close-button"
          @click="toggleSideMenu" @KeyDown="toggleSideMenu">
          <h2>X</h2>
        </div>
      </div> -->
      <nav class="side-menu-tool-container">
        <button class="button" @click="showData = !showData">DATA</button>
        <transition name="fade" appear>
          <!-- <div class="data-overlay" v-if="showData" @click="showData = false"></div> -->
        </transition>
        <transition name="slide" appear>
          <div class="side-menu" v-if="showData">
            <div class="single-tool-container">
              <dropdown :options="arrayOfDatasets" :selected="dataset" v-on:updateOption="selectDataset">
              </dropdown>
            </div>
            <p class="menu-subheading">Date Range:</p>
            <div class="date-range">
              <input class="date-input" v-model="selectedDates.startDateStr" @change="updateGlobe($event)" type="date">
              <input class="date-input" v-model="selectedDates.endDateStr" @change="updateGlobe($event)" type="date">
            </div>
            <br />
            <label for="metric-select" class="menu-subheading">Metric:</label>
            <br />
            <select v-model="selectedMetric" @change="updateGlobe($event)" name="metric-select">
              <option v-for="(item, index) in metricOptions" :value="item.value" :key="index">
                {{ item.label }}
              </option>
            </select>
            <button class="close-button" @click="showData = false">close</button>
          </div>
        </transition>
        <button class="button" @click="showDisplay = !showDisplay">DISPLAY</button>
        <transition name="fade" appear>
          <!-- <div class="data-overlay" v-if="showDisplay" @click="showDisplay = false"></div> -->
        </transition>
        <transition name="slide" appear>
          <div class="side-menu" v-if="showDisplay">
            <!-- v-if="dataset.code !== undefined && region.code !== undefined
            && region.code !== 'heatmap'"> -->
            <div class="side-menu-tool-container" v-if="showDisplay">
              <dropdown :options="arrayOfRegions" :selected="region" v-on:updateOption="selectRegion">
              </dropdown>
              <div class="display-items">
                <label for="showSiteOptions">Site Options:</label>
                <select class="show-site-options" v-model="selectedSiteOption" @change="updateSites($event)"
                  name="showSiteOptions">
                  <option v-for="item in showSiteOptions" :value="item.label" :key="item.label">{{ item.label }}</option>
                </select>
              </div>
              <div class="display-items">
                <label for="showRegionLabels">Show Region Labels:</label>
                <input type="checkbox" id="showRegionLabels" v-model="showRegionLabels" @change="updateGlobe($event)" />
              </div>
              <div class="display-items">
                <label for="squareRootScaling">Square-Root Scaling:</label>
                <input type="checkbox" id="squareRootScaling" v-model="squareRootScaling" @change="updateGlobe($event)" />
              </div>
              <div class="display-items">
                <p>Metric Lifetime (Days):</p>
                <input class="text-area" type="number" v-model="metricLifetime" />
                <button class="menu-submit-button" type="button" @click="updateGlobe">Submit</button>
              </div>
              <div class="display-items">
                <p>Maximum Connection Width:</p>
                <input class="text-area" type="number" v-model="maxConnectionWidth" />
                <button class="menu-submit-button" type="button" @click="updateGlobe">Submit</button>
              </div>
              <div class="display-items">
                <p>Canvas Multiplier:</p>
                <input class="text-area" type="number" v-model="canvasMultiplier" />
                <button class="menu-submit-button" type="button" @click="updateGlobe">Submit</button>
              </div>
              <div class="display-items">
                <p>Change with Height Multiplier:</p>
                <input class="text-area" type="number" v-model="changeWithHeightMultiplier" />
                <button class="menu-submit-button" type="button" @click="updateGlobe">Submit</button>
              </div>
            </div>
            <button class="close-button" @click="showDisplay = false">close</button>
          </div>
        </transition>
        <button class="button" @click="showCustomQuery = !showCustomQuery">CUSTOM QUERY</button>
        <transition name="fade" appear>
        </transition>
        <transition name="slide" appear>
          <div class="side-menu" v-if="showCustomQuery">
            <br />
            <div class="side-menu-tool-container" v-if="dataset.code !== undefined && region.code !== undefined">
              {{ showCustomQuery }}</div>
            <!-- <div class="side-menu-tool-container"
              v-if="dataset.code !== undefined && region.code !== undefined"> -->
            <textarea v-model="customQuery.raw" rows="6" cols="14"></textarea>
            <!-- </div> -->
            <div v-if="showCustomQuery">
              <!-- <div class="side-menu-tool-container" -->
              <!-- v-if="dataset.code !== undefined && region.code !== undefined"> -->
              <select class="custom-dropdown" v-model="customQuery.key" name="key-select">
                <option v-for="(item, index) in customQuery.arrayOfKeys" :value="item.name" :key="index">{{ item.name }}
                </option>
              </select>
              <select class="custom-dropdown" v-model="customQuery.operator" name="operator-select">
                <option v-for="(item, index) in customQuery.arrayOfOperators" :value="item.name" :key="index">{{ item.name
                }}
                </option>
              </select>
              <input class="custom-dropdown" type="number" v-model="customQuery.value" />
            </div>
            <div v-if="showCustomQuery">
              <!-- <div class="hovering-tool-container"
            v-if="dataset.code !== undefined && region.code !== undefined"> -->
              <button class="custom-button" type="button" @click="addQuery">Add</button>
              <button class="custom-button" type="button" @click="selectQuery">Submit</button>
              <button class="custom-button" type="button" @click="resetQuery">Reset</button>
            </div>
            <button class="close-button" @click="showCustomQuery = false">close</button>
          </div>
        </transition>
        <button class="button" @click="showRoutePlanning = !showRoutePlanning">
          ROUTE PLANNING</button>
        <transition name="fade" appear>
        </transition>
        <transition name="slide" appear>
          <div class="side-menu" v-if="showRoutePlanning">
            <!-- <div class="side-menu-tool-container" v-if="showRoutePlanning"> -->
            <!-- <div class="side-menu-tool-container routePlanner"
            v-if="dataset.code !== undefined && region.code !== undefined"> -->
            <RoutePlanner v-on:setPlanningRoute="setPlanningRoute" />
            <!-- </div> -->
            <button class="close-button" @click="showRoutePlanning = false">close</button>
          </div>
        </transition>
      </nav>
    </div>
  </div>
</template>
<script>
import dropdown from 'vue-dropdowns';
import common from '@sensorium/cesium-common';
import VueSimpleAlert from 'vue-simple-alert';
import { Auth } from 'aws-amplify';
import RoutePlanner from './RoutePlanner.vue';
import SensorsDropdown from './SensorsDropdown.vue';
import packageInfo from '../../package.json';

async function getCurrentUserToken() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const { jwtToken } = currentUser.signInUserSession.idToken;
    return jwtToken;
  } catch (error) {
    console.error('Error getting user token:', error);
    return null;
  }
}

// Set Environment
const environment = {};
const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'dev';

if (subdomain === 'dev') {
  environment.InitialCall = process.env.VUE_APP_GET_INITIAL_CALL_DEV;
} else {
  environment.InitialCall = process.env.VUE_APP_GET_INITIAL_CALL_PROD;
}

export default {
  name: 'Toolbar',
  data() {
    return {
      version: packageInfo.version,
      showData: false,
      showDisplay: false,
      showRoutePlanning: false,
      showCustomQuery: false,
      burgerImage: 'https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/burger-icon.png',
      burgerImageStd: 'https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/burger-icon.png',
      burgerImageHover: 'https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/burger-icon-gold.png',
      userImage: 'https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/user-icon.png',
      userImageStd: 'https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/user-icon.png',
      userImageHover: 'https://sensoriumimagerepo-s3-origin-sensoriumimagerepo-394938779597.s3.eu-west-2.amazonaws.com/icons/user-icon-gold.png',
      elkUrl: process.env.VUE_APP_ELK_URL,
      sigUrl: process.env.VUE_APP_ELK_SIG_URL,
      elkCreds: process.env.VUE_APP_ELK_CREDS,
      selectedAPI: undefined,
      selectedDates: {
        startDateStr: '',
        endDateStr: '',
      },
      selectedMetric: '',
      metricOptions: [],
      waterMetricOptions: [],
      showSiteOptions: [
        { label: 'Hide Sites' },
        { label: 'Show Sites' },
        { label: 'Show Sites & Names' },
        { label: 'Show Sites & Sources' },
        // { label: 'Show Sites Connected' },
        // { label: 'Show Sites & Heatmap' },
        { label: 'Show Sites Path' }, // To show animimal migration movements
      ],
      selectedSiteOption: 'Hide Sites',
      showRegionLabels: false,
      selectedShowBreweries: false,
      selectedShowCompanies: false,
      selectedShowPowerstations: false,
      squareRootScaling: false,
      metricLifetime: 7, // Days that the metric / source will be considered valid for
      maxConnectionWidth: 50, // Max width of line defining a connection of a source between areas
      canvasMultiplier: 0.9, // Optimal default value for Airborne Dispersal heatmap
      changeWithHeightMultiplier: 4.0, // Optimal default value for Airborne Dispersal heatmap
      arrayOfDatasets: [],
      arrayOfRegions: [],
      dataset: { name: 'Select a Dataset' },
      region: { name: 'Select a Region' },
      customQuery: {
        raw: '[\n\n]',
        arrayOfKeys: [],
        arrayOfOperators: [
          { name: '=' },
          { name: '>=' },
          { name: '<=' },
        ],
        key: '',
        operator: '',
        value: '',
      },
      datasetAPI: new common.datasets.Dataset(),
    };
  },

  components: {
    dropdown,
    RoutePlanner,
    SensorsDropdown,
  },

  async beforeMount() {
    const loading = VueSimpleAlert.fire({
      title: 'Loading',
      text: 'Finding datasets, regions and sensors...',
      type: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });

    await this.addDatasets();
    await this.addRegions();

    this.arrayOfDatasets.sort((a, b) => b.name.localeCompare(a.name, undefined, { numeric: true }));
    this.arrayOfRegions.sort((a, b) => b.name.localeCompare(a.name, undefined, { numeric: true }));

    loading.close();
  },

  methods: {
    signOut: () => {
      Auth.signOut()
        .then((data) => {
          console.log('Sign-out successfully!', data);
        })
        .catch((err) => console.log(err));
    },
    async addDatasets() {
      // Get the current user's token
      const token = await getCurrentUserToken();

      // Check if the token is available
      if (!token) {
        console.error('User is not authenticated');
        return;
      }

      // Send ELK get request to query what indices are populated

      const mylistOfIndices = await fetch(`${environment.InitialCall}?esi=_aliases?pretty=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const listOfIndices = await mylistOfIndices.json();

      /**
       * Find all datasets
       * A dataset is confirmed to exist when the following indices exist in Elastic:
       *  - X-metrics
       *  - X-summary
       */

      this.arrayOfDatasets = [];

      const requiredSuffixes = ['metrics', 'summary'];
      const potentialDatasets = {};

      // Count occurrences of indices having each required suffix
      for (const index of Object.keys(await listOfIndices)) {
        for (const suffix of requiredSuffixes) {
          const indexList = index.split('-'); // Split by '-' into list
          if (indexList[indexList.length - 1] === suffix && indexList[0] !== 'sensor') {
            indexList.pop(); // Remove suffix
            potentialDatasets[indexList.join('-')] = potentialDatasets[indexList.join('-')] ? potentialDatasets[indexList.join('-')] + 1 : 1; // Convert list to string
          }
        }
      }

      // Confirm if a dataset exists by checking it has all the required suffixes.
      for (const potentialDataset of Object.keys(potentialDatasets)) {
        if (potentialDatasets[potentialDataset] === requiredSuffixes.length) {
          this.arrayOfDatasets.push({
            name: potentialDataset.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()),
            code: potentialDataset,
          });
        }
      }
    },

    async addRegions() {
      // Get the current user's token
      const token = await getCurrentUserToken();

      // Check if the token is available
      if (!token) {
        console.error('User is not authenticated');
        return;
      }

      // Send ELK get request to query what indices are populated
      const mylistOfIndices = await fetch(`${environment.InitialCall}?esi=_aliases?pretty=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const listOfIndices = await mylistOfIndices.json();

      /**
       * Loop through indices and find any prefixing 'regions-' (indicating a GeoJSON regions file)
       */

      // Add heatmap to regions list
      this.arrayOfRegions = [{ name: 'Heatmap', code: 'heatmap' }];

      for (const index of Object.keys(await listOfIndices)) {
        if (index.split('-')[0] === 'regions') {
          // Get file size of region
          // eslint-disable-next-line no-await-in-loop
          const myregionStats = await fetch(`${environment.InitialCall}?esi=_stats`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // eslint-disable-next-line no-await-in-loop
          const regionStats = (await (await myregionStats.json()))._all.primaries;

          const noOfAreas = regionStats.docs.count;
          const size = regionStats.store.size_in_bytes / 1000000;

          this.arrayOfRegions.push({
            name: `${index.split('-').slice(1).join(' ').replace(/-/g, ' ')
              .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
              \n(${noOfAreas} areas, ${size.toFixed(2)}MB)
            `,
            code: index,
          });
        }
      }

      // Set the dropdown selected option to the first element of the regions array
      // TODO: Fix as does not update in Vue / Cesium
      // if (this.arrayOfRegions.length > 0) {
      //   [this.region] = this.arrayOfRegions;
      // }

      // this.$set(this.region, this.arrayOfRegions[0], {});
    },

    // Method called when the 'Reset Viewer' button is clicked to fly to the default
    // dataset viewing position
    async resetViewer() {
      // Check that both dataset and region have been selected
      if (this.dataset.name !== 'Select a Dataset' && this.region.name !== 'Select a Region') {
        this.$emit('resetViewer');
      }
    },

    // Method called when the 'Submit' button is clicked to update the custom query
    async selectQuery() {
      try {
        this.datasetAPI.setDataQuery = JSON.parse(this.customQuery.raw);
        await this.updateGlobe();
      } catch (e) {
        VueSimpleAlert.fire({
          title: 'Error',
          text: `Unable to convert custom query into valid JSON - ${e}`,
          type: 'error',
        });
      }
    },

    // Method called when the 'Reset' button is clicked to reset the input box
    // to the default query
    async resetQuery() {
      this.customQuery.raw = '[\n\n]';
    },

    // Populate the query keys dropdown menu with the keys in the first document of the
    // metric index
    async getQueryKeys() {
      const keys = Object.keys(
        (await this.datasetAPI.getData(this.selectedDates, this.selectedMetric)),
      );
      const arrayOfKeys = [];
      for (const key of keys) {
        arrayOfKeys.push({ name: key });
      }
      return arrayOfKeys;
    },

    // Method called when the 'Add' button is clicked to add the specified operation
    // to the custom Elastic query
    async addQuery() {
      // Check that both key and operator have been selected
      if (this.customQuery.key !== '' && this.customQuery.operator !== '') {
        // Add query to raw query field
        const query = JSON.parse(this.customQuery.raw);
        if (this.customQuery.operator === '=') {
          query.push({
            match: {
              [this.customQuery.key]: this.customQuery.value,
            },
          });
        } else {
          query.push({
            range: {
              [this.customQuery.key]: {
                [this.customQuery.operator === '>=' ? 'gte' : 'lte']: this.customQuery.value,
              },
            },
          });
        }
        this.customQuery.raw = JSON.stringify(query, undefined, 2);
      }

      // Reset dropdown fields
      this.customQuery.key = '';
      this.customQuery.operator = '';
      this.customQuery.value = '';
    },

    /* Method called when a sensor set is selected from the dropdown menu
     * @param sensorSelections {Dictionary} with 3 attributes per field:
     *        sensor {String} representing sensor set name
     *        check {Boolean} whether it has been selected or deselected
     *        countryCode {String} provides country code for selected set
     */
    async sensorEvent(sensorSelections) {
      try {
        this.$emit('showSensors', sensorSelections);
      } catch (err) {
        VueSimpleAlert.fire({
          title: 'Error',
          text: 'Unable to display sensor set!',
          type: 'error',
        });
      }
    },

    // Method called when a dataset is selected from the dropdown menu
    async selectDataset(payload) {
      this.dataset = payload;

      const loading = VueSimpleAlert.fire({
        title: 'Loading',
        text: 'Downloading dataset metadata...',
        type: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      // Get metric matchings
      const metricMatchings = await common.metricMatchings.source.getMatchings(this.dataset.code);

      // Reset regions dropdown list
      await this.addRegions();

      // Embed metric matchings total into dropdown title for each region with the selected dataset
      for (let i = 0; i < this.arrayOfRegions.length; i++) {
        const matchedTotal = metricMatchings.filter(
          (x) => x.region === this.arrayOfRegions[i].code,
        );

        if (matchedTotal.length > 0) {
          this.arrayOfRegions[i].name = `${matchedTotal[0].total.toFixed(1)}% - ${this.arrayOfRegions[i].name}`;
        }
      }

      // Sort regions by match percentage
      this.arrayOfRegions.sort(
        (a, b) => b.name.localeCompare(a.name, undefined, { numeric: true }),
      );

      // Update selected region name
      if (this.region.code !== undefined) {
        this.region.name = this.arrayOfRegions.filter((x) => x.code === this.region.code)[0].name;
      }

      this.datasetAPI.setDataset = this.dataset;

      // Update metric options dropdown list and default selected metric
      this.metricOptions = await this.datasetAPI.getMetrics();
      this.selectedMetric = this.metricOptions[0].value;

      // Calculate the start and end dates over which the data takes place
      try {
        this.selectedDates = await this.datasetAPI.getDateRange(this.selectedMetric);
      } catch (e) {
        VueSimpleAlert.fire({
          title: 'Error',
          text: `Error finding metrics with the metric title '${this.selectedMetric}' - ${e}`,
          type: 'error',
        });
      }

      // Add query keys
      this.customQuery.arrayOfKeys = await this.getQueryKeys();

      loading.close();

      await this.updateGlobe();
    },

    // Method called when a region is selected from the dropdown menu
    async selectRegion(payload) {
      this.region = payload;

      await this.updateGlobe();
    },

    // Method called when show breweries checkbox is toggled
    async showBreweries() {
      this.$emit('showBreweries', this.selectedShowBreweries);
    },

    // Method called when show companies checkbox is toggled
    async showCompanies() {
      this.$emit('showCompanies', this.selectedShowCompanies);
    },
    // Method called when show power stations checkbox is toggled
    async showPowerstations() {
      this.$emit('showPowerstations', this.selectedShowPowerstations);
    },

    async updateSites() {
      if (this.dataset.name !== 'Select a Dataset') {
        this.$emit('updateSites', this.datasetAPI, this.selectedDates, this.selectedMetric, {
          selectedSiteOption: this.selectedSiteOption,
          metricLifetime: this.metricLifetime,
        });
      }
    },

    // Update the display with any updated selections
    async updateGlobe() {
      // Check that both dataset and region have been selected
      if (this.dataset.name !== 'Select a Dataset' && this.region.name !== 'Select a Region') {
        this.$emit('update', this.datasetAPI, this.selectedDates, this.selectedMetric, {
          regionName: this.region.code,
          selectedSiteOption: this.selectedSiteOption,
          showRegionLabels: this.showRegionLabels,
          squareRootScaling: this.squareRootScaling,
          metricLifetime: this.metricLifetime,
          maxConnectionWidth: this.maxConnectionWidth,
          canvasMultiplier: this.canvasMultiplier,
          changeWithHeightMultiplier: this.changeWithHeightMultiplier,
        });
      }
    },

    setPlanningRoute(areWePlanning) {
      // true/false
      this.planningRoute = areWePlanning;
    },

    // Method called when show cameras checkbox is toggled
    async cameraEvent({ cam, check }) {
      try {
        this.$emit('showCameras', check, cam);
      } catch (e) {
        VueSimpleAlert.fire({
          title: 'Error',
          text: 'Unable to show camera set',
          type: 'error',
        });
      }
    },
    // Toggle between showing and hiding the timeline, speed selector and heatmap key
    toggleTimeBar() {
      if (document.getElementsByClassName('cesium-viewer-animationContainer')[0].style.display === '') {
        this.hideTimeBar();
      } else this.showTimeBar();
    },
    // Displays timeline, animated speed selector and heatmap key
    showTimeBar() {
      document.getElementsByClassName('cesium-viewer-animationContainer')[0].style.display = '';
      document.getElementsByClassName('cesium-viewer-timelineContainer')[0].style.display = '';
      document.getElementsByClassName('sensorium-heatmap-key')[0].style.display = '';
    },
    // Hides timeline, animated speed selector and heatmap key
    hideTimeBar() {
      document.getElementsByClassName('cesium-viewer-animationContainer')[0].style.display = 'none';
      document.getElementsByClassName('cesium-viewer-timelineContainer')[0].style.display = 'none';
      document.getElementsByClassName('sensorium-heatmap-key')[0].style.display = 'none';
    },
    // Sensor Dropdown Toggle
    toggleSensorDropdown() {
      const status = document.getElementsByClassName('sensors-dropdown-container')[0].style.display;
      if (status === 'block') {
        document.getElementsByClassName('sensors-dropdown-container')[0].style.display = 'none';
      } else {
        document.getElementsByClassName('sensors-dropdown-container')[0].style.display = 'block';
      }
    },

    // POI Button Toggle
    togglePoiDropdown() {
      const status = document.getElementsByClassName('poi-dropdown-container')[0].style.display;
      if (status === 'flex') {
        document.getElementsByClassName('poi-dropdown-container')[0].style.display = 'none';
      } else {
        document.getElementsByClassName('poi-dropdown-container')[0].style.display = 'flex';
      }
    },

    // User Dropdown Toggle
    toggleUserDropdown() {
      const status = document.getElementsByClassName('user-dropdown-container')[0].style.display;
      if (status === 'block') {
        document.getElementsByClassName('user-dropdown-container')[0].style.display = 'none';
      } else {
        document.getElementsByClassName('user-dropdown-container')[0].style.display = 'block';
      }
    },

    // Burger Menu Toggle
    toggleSideMenu() {
      const status = document.getElementsByClassName('side-menu-container')[0].style.display;
      if (status === 'block') {
        document.getElementsByClassName('side-menu-container')[0].style.display = 'none';
      } else {
        document.getElementsByClassName('side-menu-container')[0].style.display = 'block';
      }
    },
  },
};
</script>

<style scoped>
.toolbar-container,
.single-tool-container,
time-bar-switch {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.side-menu,
.single-tool-container {
  background-color: rgb(48, 48, 48);
}

.toolbar-container {
  padding: 0 50px;
  gap: 3.5em;
  align-items: center;
}

.burger-menu-container {
  padding: 0;
  margin: 0;
}

.title-container {
  padding: 0.4em 0;
  display: flex;
  flex-direction: column;
}

.app-title,
.version-number {
  padding: 0;
  margin: 0;
}

.app-title {
  font-size: 1.3rem;
}

.version-number {
  font-weight: 400;
  font-size: 0.7rem
}

#reset-button {
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.75);
  color: #B78316;
  border: 1px solid #B78316;
  border-radius: 5px;
  transition: ease-in-out;
}

#reset-button:hover {
  cursor: pointer;
  color: black;
  background-color: #B78316;
}

.user-menu-container {
  align-items: center;
  text-align: center;
}

#toolbar-spacer {
  margin-left: auto;
}

#timebar-toggle-container {
  flex-direction: row;
  gap: 1em;
}

.user-menu-item {
  text-align: right;
}

.user-menu-item label:hover {
  color: #B78316;
  cursor: pointer;
}

.tools {
  display: flex;
  gap: 1.5em;
  align-items: center;
  flex-grow: 1;
}

.side-menu-container {
  height: auto;
  display: none;
  position: absolute;
  top: 50px;
  left: 20px;
  width: 240px;
  background-color: black;
  text-align: center;
  align-items: center;
}

.side-menu-close-button {
  text-align: right;
}

.side-menu-close-button h2:hover {
  color: #B78316;
  cursor: pointer;
}

.side-menu-container h1 {
  margin: 0;
}

.single-tool-container>p,
.single-tool-container>label {
  margin-right: 0.2em;
}

::v-deep .dropdown-toggle {
  color: white;
  font-size: 12px;
  width: 150px;
  text-align: center;
}

::v-deep .dropdown-toggle:hover {
  color: #636b6f;
}

::v-deep .dropdown-menu {
  width: 100%;
  text-align: left;
}

::v-deep .dropdown-menu li,
::v-deep .dropdown-menu li a {
  font-size: 13px;
  white-space: normal;
  height: auto;
  word-wrap: break-word;
}

::v-deep .line-style {
  position: absolute;
  height: 20%;
}

.routePlanner {
  margin-left: 20px;
}

.text-area {
  resize: none;
  width: 20px;
  font-size: 12px;
}

.menu-submit-button {
  font-size: 12px;
}

.p1 {
  justify-content: center;
  margin-top: 0;
  margin-right: 15px;
}

.sensorsButton:hover,
.poiButton:hover {
  color: #B78316;
  cursor: pointer;
}

.sensors-dropdown-container,
.user-dropdown-container {
  position: absolute;
  display: none;
  top: 50px;
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px;
  background-color: black;
}

.poi-dropdown-container {
  position: absolute;
  display: none;
  left: 400px;
  padding: 0.8em;
  gap: 1rem;
  flex-direction: column;
  top: 50px;
  background-color: black;
}

.poi-dropdown-container label:hover {
  color: #B78316;
}

.poi-container {
  display: flex;
  justify-content: flex-start;
  gap: 0.8rem;
}

.poi-img {
  height: 20px;
  width: 20px;
  margin-left: auto;
}

.sensors-dropdown-container {
  left: 290px;
}

.user-dropdown-container {
  right: 35px;
}

.user-icon img {
  height: 100%;
  width: 100%;
}

.user-icon img:hover {
  cursor: pointer;
}

.burger-icon,
.user-icon {
  margin: 0;
  padding: 0;
  height: 20px;
  width: 20px;
}

.burger-icon img {
  height: 100%;
  width: 100%;
}

.burger-icon img:hover {
  cursor: pointer;
}

nav .button {
  color: white;
  background-color: black;
  padding: 10px 20px;
  border: 1px solid transparent;
  transition: 0.2s;
  border-radius: 5%;
  font-size: 0.8rem;
}

nav .button.active,
nav .button:hover {
  border: 1px solid rgb(121, 94, 25);
  box-sizing: border-box;
}

.side-menu {
  background-color: black;
  align-self: center
}

.side-menu,
.single-tool-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.side-menu-tool-container {
  font-size: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5em;
  padding: 1.5em;
}

.single-tool-container,
.dropdown-toggle {
  background-color: black;
}

.date-input {
  background-color: black;
  color: white;
  border: 1px solid white;
  width: 70px;
  height: 15px;
  font-size: 10px;
  margin-right: 15px;
}

.menu-subheading {
  font-size: 12px;
  text-align: left;
}

.date-input-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.close-button {
  border: 1px solid white;
  background-color: black;
  color: white;
  margin-top: 10px;
  padding: 5px;
}

.close-button:hover {
  background-color: #8d640b;
}

.display-items {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}

.custom-dropdown {
  width: 50px;
  margin: 5px;
  font-size: 12px;
  border: none;
}

.custom-button {
  width: 50px;
  margin: 5px;
  font-size: 12px;
  border: none;
}

.custom-button:hover {
  background-color: white;
}

.show-site-options {
  margin-left: 10px;
  background-color: black;
  color: white;
  font-size: 12px;
}

/* scrollbar settings  */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(121, 94, 25);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 94, 25);
}

body {
  margin: 0;
  padding: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #B78316;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: #000000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #ffffff;
}

input:focus+.slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
