<!--
Sensors dropdown menu
-->

<template>
  <div>
    <div>
      <div v-for="(sensorType, sensorKey) in this.arrayOfSensorTypes"
      class="sensor-item" :key="sensorKey">
        <button class="sensor-btn" :id="'sensor' + sensorType.name + 'Btn'"
          :class="{ sensorLive: sensorType.activeSensors }"
          @click="selectSensorGroup(sensorType.name, sensorType.activeSensors)"
          @focus="selectSensorGroup(sensorType.name, sensorType.activeSensors)">
          <img :src="sensorType.imageUrl" :alt="sensorType.name" width=55 height=55 />
          <div>
            <label class="sensor-title" :class="{ sensorLive: sensorType.activeSensors }">
              {{ sensorType.name }}
            </label>
          </div>
        </button>
      </div>
    </div>
    <SensorsItemsMenuVue v-show="this.showModal" @close-modal="closeSensorMenu"
    :sensorMenuName="this.sensorName" :sensorsToShow="this.sensorsToShow" />
  </div>
</template>
<script>
import { Auth } from 'aws-amplify';
import SensorsItemsMenuVue from './SensorsItemsMenu.vue';

export default {
  name: 'SensorsDropdown',
  components: { SensorsItemsMenuVue },
  data() {
    return {
      elkUrl: process.env.VUE_APP_ELK_URL,
      sigUrl: process.env.VUE_APP_ELK_SIG_URL,
      elkCreds: process.env.VUE_APP_ELK_CREDS,

      showModal: false,
      sensorName: '',
      sensorsToShow: [],

      arrayOfSensors: [],
      arrayOfSensorTypes: [],
    };
  },

  async beforeMount() {
    await this.addSensors();
  },

  methods: {
    // Get sensors dropdown information from Elastic, including sensor types and sensor sets
    async addSensors() {
      async function getCurrentUserToken() {
        try {
          const currentUser = await Auth.currentAuthenticatedUser();
          const { jwtToken } = currentUser.signInUserSession.idToken;
          return jwtToken;
        } catch (error) {
          console.error('Error getting user token:', error);
          return null;
        }
      }
      // Get the current user's token
      const token = await getCurrentUserToken();

      // Check if the token is available
      if (!token) {
        console.error('User is not authenticated');
        return;
      }
      // Set Environment
      const environment = {};
      const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'dev';

      if (subdomain === 'dev') {
        environment.InitialCall = process.env.VUE_APP_GET_INITIAL_CALL_DEV;
      } else {
        environment.InitialCall = process.env.VUE_APP_GET_INITIAL_CALL_PROD;
      }

      // Get sensor types and images from sensor-summary
      const firstLiveSensors = await fetch(`${environment.InitialCall}?esi=sensors-summary/_search?pretty=true&q=*:*`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const liveSensors = await firstLiveSensors.json();

      if (liveSensors.hits === undefined) return; // If index does not exist, do not continue

      for (let count = 0; count < liveSensors.hits.hits.length; count++) {
        this.arrayOfSensorTypes.push({
          name: liveSensors.hits.hits[count]._source.name,
          imageUrl: liveSensors.hits.hits[count]._source.image_url,
          activeSensors: false,
        });
      }

      // Get aliases from elastic and filter by sensor summaries
      const firstIndexListRaw = await fetch(`${environment.InitialCall}?esi=_aliases?pretty=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const indexListRaw = await firstIndexListRaw.json();

      const indexList = [];
      for (const index of Object.keys(await indexListRaw)) {
        const indexSplit = index.split('-');
        const initialName = indexSplit[0];
        const lastName = indexSplit[indexSplit.length - 1];
        if ((initialName === 'sensor') && (lastName === 'summary')) {
          indexList.push(index);
        }
      }

      // Join summaries list and search for all sensor summaries
      const stringOfSummaries = indexList.join(',');

      const firstFoundSummaries = await fetch(`${environment.InitialCall}?esi=${stringOfSummaries}/_search?size=1000`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const foundSummaries = await firstFoundSummaries.json();

      const summaryList = Object.entries(await foundSummaries)[3][1].hits;

      /* Loop through summaries and push to sensors array for each summary found
       * Check against each sensor type and activate type if summary is of said type
       */
      for (const summary of summaryList) {
        const sumSource = summary._source;
        this.arrayOfSensors.push({
          name: sumSource.friendly_set,
          code: sumSource.set,
          countryCode: sumSource.country_code,
          country: sumSource.country,
          type: sumSource.sensor,
          active: false,
        });
        for (let i = 0; i < this.arrayOfSensorTypes.length; i++) {
          if (this.arrayOfSensorTypes[i].name === sumSource.sensor) {
            this.arrayOfSensorTypes[i].activeSensors = true;
          }
        }
      }
    },

    /* Function adds sensors for selected type and countries they reside in to dictionary lists
     * @param sensorName {String} The name of the sensor type selected (e.g. 'CCTV')
     * @param activeSensors {Boolean} Whether the sensor type is active or not (if it has sensors)
     */
    selectSensorGroup(sensorName, activeSensors) {
      if (activeSensors) {
        this.sensorsToShow = {};
        this.sensorName = sensorName;

        // For each sensor, push to list within specific country if of correct sensor type
        for (const sensor of this.arrayOfSensors) {
          if (sensor.type === sensorName) {
            if (this.sensorsToShow[sensor.country] !== undefined) {
              this.sensorsToShow[sensor.country].push(sensor);
            } else {
              this.sensorsToShow[sensor.country] = [sensor];
            }
          }
        }

        // Show menu
        this.showModal = true;
      }
    },

    /* Function closes menu and displays/removes sets if a selection is made
     * @param sensorSelections {Dictionary} selected sensor sets
     */
    closeSensorMenu(sensorSelections) {
      this.showModal = false;
      if (Object.keys(sensorSelections).length > 0) {
        this.$emit('change', sensorSelections);
      }
    },
  },
};
</script>
<style>
/* Dropdown Buttons */
.dropbtn {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropbtn:hover {
  color: #B78316;
  cursor: pointer;
}

.sensor-item {
  display: inline-block;
  background-color: black;
  height: 100px;
  width: 100px;
  font-size: 16px;
  border: none;
  text-align: center;
}

.sensor-btn {
  display: block;
  background-color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border: none;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.45;
  filter: saturate(50%) sepia(20%);
  -webkit-filter: saturate(50%) sepia(20%);
}

.sensor-title {
  display: block;
  background-color: black;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border: none;
  opacity: 0.45;
  filter: saturate(50%) sepia(20%);
  -webkit-filter: saturate(50%) sepia(20%);
}

.sensorLive {
  opacity: 0.8;
  color: white;
  filter: saturate(100%) sepia(0);
  -webkit-filter: saturate(100%) sepia(0);
}

.sensorLive:hover {
  opacity: 1.0;
}

/* The container <div> - needed to position the dropdown content */
.dropdown,
.country-dropdown {
  position: relative;
  /* display: inline-block; */
}

.test {
  position: absolute;
  height: 200px;
  width: 400px;
  background-color: red;
  top: 200px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: transparent;
  padding: 10px;
  left: -700px;
  right: auto;
  width: 900px;
  height: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.available-sensors-dropdown {
  position: relative;
  display: block;
  background-color: black;
  height: 100px;
  padding: 10px;
}

.available-sensors-dropdown-content {
  display: block;
  position: absolute;
  top: 0;
  left: 10rem;
  background-color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.sensor-country-dropdown-content {
  display: none;
  position: absolute;
  background-color: blue;
  padding: 10px;
  left: 0;
  right: auto;
  width: 880px;
  height: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.available-countries-dropdown {
  position: relative;
  display: block;
  background-color: black;
  height: 100px;
  padding: 10px;
}

/* Links inside the dropdown */
.dropdown-content label,
.available-sensors-dropdown-content label {
  color: white;
  padding: 5px;
  text-decoration: none;
  display: inline-block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover,
.available-sensors-dropdown-content a:hover {
  background-color: grey;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content,
.available-sensors-dropdown:hover .available-sensors-dropdown-content,
.country-dropdown,
.sensor-country-dropdown-content:hover {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* .dropdown:hover .dropbtn {background-color: lightgray;} */
</style>
