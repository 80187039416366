import Vue from 'vue';
import VueCesium from 'vue-cesium';
import lang from 'vue-cesium/lang/en-us';
import { Amplify } from 'aws-amplify';
import * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import App from './App.vue';

// TODO Remove hardcoded creds
Amplify.configure({
  Auth: {
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITYPOOLID,
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USERPOOL_CLIENT,
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: process.env.VUE_APP_COGNITO_SCOPE.split(','),
      redirectSignIn: process.env.VUE_APP_COGNITO_SIGNIN_URL,
      redirectSignOut: process.env.VUE_APP_COGNITO_SIGNOUT_URL,
      responseType: 'code',
    },
  },
});

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueCesium, {
  // cesiumPath is the web service address that guides the use of Cesium.js,
  // which can be a local orCDN address
  cesiumPath: './libs/Cesium/Cesium.js',
  lang,
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
