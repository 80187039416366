<template>
  <div class="viewer">
    <vc-viewer
      class="viewer"
      :animation="animation"
      :timeline="timeline"
      :sceneModePicker="sceneModePicker"
      :baseLayerPicker="baseLayerPicker"
      @ready="ready"
      @LEFT_CLICK="LEFT_CLICK"
    >
      <vc-layer-imagery>
        <vc-provider-imagery-ion
          :asset-id="2"
          :access-token="accessToken"
        ></vc-provider-imagery-ion>
      </vc-layer-imagery>
    </vc-viewer>
    <div class="sensorium-toolbar">
      <Toolbar
        v-if="this.signedIn"
        v-on:update="update"
        v-on:resetViewer="resetViewer"
        v-on:showSensors="showSensors"
        v-on:showBreweries="showBreweries"
        v-on:showCompanies="showCompanies"
        v-on:updateSites="updateSites"
      />
    </div>
    <div class="sensorium-heatmap-key">
      <HeatKey :heatKeyTitle="heatKeyTitle" :colourMax="colourMax" :colourMin="colourMin" />
    </div>
  </div>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import VueSimpleAlert from 'vue-simple-alert';
import common from '@sensorium/cesium-common';
import Toolbar from './Toolbar.vue';
import HeatKey from './HeatKey.vue';

export default {
  name: 'Globe',
  components: {
    HeatKey,
    Toolbar,
  },
  data() {
    return {
      signedIn: false,
      username: String,
      heatKeyTitle: '',
      colourMax: 0,
      colourMin: 0,

      animation: true,
      timeline: true,
      sceneModePicker: true,
      baseLayerPicker: true,

      // Not sure who owns this token.... Might be EF?
      accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYWE1OWUxNy1mMWZiLTQzYjYtYTQ0OS1kMWFjYmFkNjc5YzciLCJpZCI6NTc3MzMsImlhdCI6MTYyNzg0NTE4Mn0.XcKpgANiY19MC4bdFUXMVEBToBmqS8kuYpUlxJHYZxk',
    };
  },

  methods: {
    getAuthInstance: () => Auth,
    signIn: () => {
      Auth.federatedSignIn(); // Cognito is the default provider
    },
    signOut: () => {
      Auth.signOut()
        .then((data) => {
          console.log('Sign-out successfully!', data);
        })
        .catch((err) => console.log(err));
    },
    ready(CesiumInstance) {
      // Gives us access to the vanilla way of doing Cesium stuff (rather than using Vue components)
      const { Cesium, viewer } = CesiumInstance;
      this.Cesium = Cesium;
      this.viewer = viewer;

      viewer.infoBox.frame.removeAttribute('sandbox'); // For displaying iframes in infoboxes
      viewer.infoBox.frame.src = 'about:blank'; // Force reload of changes

      this.routeEntities = [];
      this.routeConnectors = [];
      this.planningRoute = false;

      this.Sensors = new common.sensors.Sensors(this);
      this.companies = [];
      this.breweries = [];
      this.Sites = new common.sites.Sites(this);
      this.Heatmap = new common.heatmap.Heatmap(this);
      this.entities = [];

      this.viewer.clock.clockRange = this.Cesium.ClockRange.LOOP_STOP; // loop when we hit the end
      this.viewer.clock.clockStep = this.Cesium.ClockStep.SYSTEM_CLOCK_MULTIPLIER;
      this.viewer.clock.multiplier = 200000; // how much time to advance each tick
      this.viewer.clock.shouldAnimate = false;

      // Hide vanilla cesium-viewer-toolbar - options re-created on sensorium toolbar
      document.getElementsByClassName('cesium-viewer-toolbar')[0].style.display = 'none';
    },

    startClockAnimation() {
      this.viewer.clock.shouldAnimate = true;
    },
    stopClockAnimation() {
      this.viewer.clock.shouldAnimate = false;
    },

    // Remove any selection the user currently has made
    deSelect() {
      this.viewer.selectedEntity = undefined;
    },

    // Update the clock to the given dates
    updateViewerClock(startDateStr, endDateStr) {
      const startDate = this.Cesium.JulianDate.fromIso8601(startDateStr);
      const endDate = this.Cesium.JulianDate.fromIso8601(endDateStr);

      this.viewer.clock.startTime = startDate;
      this.viewer.clock.currentTime = startDate;
      this.viewer.clock.stopTime = endDate;

      this.viewer.timeline.updateFromClock();
      this.viewer.timeline.zoomTo(this.viewer.clock.startTime, this.viewer.clock.stopTime);
    },

    // Fly camera to location that displays the entire data source
    async resetViewer() {
      this.viewer.flyTo(this.viewer.dataSources.get(0));
    },

    /* Show Sensors as points on map
     * @param sensorSelections {Dictionary} containing attributes for each sensor set:
     *        check {Boolean} showing whether to show or hide points (from checkbox)
     *        sensor {String} the code name for the sensor set
     *        countryCode {String} the country code for the sensors to be displayed
     */
    async showSensors(sensorSelections) {
      // Display loading prompt for the duration of this function
      const loading = VueSimpleAlert.fire({
        title: 'Loading',
        text: 'Displaying selected sensors...',
        type: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      // Loop through selected items to display or remove
      for (const selection of Object.values(sensorSelections)) {
        if (selection.check) {
          // Calling processor function to display points if checkbox ticked
          // eslint-disable-next-line
          await this.Sensors.createEntities(selection.sensor, selection.countryCode);
        } else {
          // If checkbox unticked then removes points from globe
          this.Sensors.deleteEntities(selection.sensor, selection.countryCode);
        }
      }

      loading.close();
      VueSimpleAlert.fire({
        title: 'Done',
        type: 'success',
        timer: 5000,
        showConfirmButton: true,
      });
    },

    // Show breweries
    async showBreweries(show) {
      // Display loading prompt for the duration of this function
      const loading = VueSimpleAlert.fire({
        title: 'Loading',
        text: `${show ? 'Showing' : 'Hiding'} breweries...`,
        type: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      if (show) {
        this.breweries = await common.breweries.process.showBreweries(this);
      } else {
        for (let i = 0; i < this.breweries.length; i++) {
          this.viewer.entities.remove(this.breweries[i]);
        }
        this.breweries = [];
      }

      loading.close();
      VueSimpleAlert.fire({
        title: 'Done',
        type: 'success',
        timer: 5000,
        showConfirmButton: true,
      });
    },

    // Show Companies
    async showCompanies(show) {
      // Display loading prompt for the duration of this function
      const loading = VueSimpleAlert.fire({
        title: 'Loading',
        text: `${show ? 'Showing' : 'Hiding'} companies...`,
        type: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      if (show) {
        this.companies = await common.companies.process.showCompanies(this);
      } else {
        for (let i = 0; i < this.companies.length; i++) {
          this.viewer.entities.remove(this.companies[i]);
        }
        this.companies = [];
      }

      loading.close();
      VueSimpleAlert.fire({
        title: 'Done',
        type: 'success',
        timer: 5000,
        showConfirmButton: true,
      });
    },

    // Show Power stations
    async showPowerstations(show) {
      // Display loading prompt for the duration of this function
      const loading = VueSimpleAlert.fire({
        title: 'Loading',
        text: `${show ? 'Showing' : 'Hiding'} power stations...`,
        type: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      if (show) {
        this.powerstationss = await common.powerstations.process.showPowerstations(this);
      } else {
        for (let i = 0; i < this.powerstations.length; i++) {
          this.viewer.entities.remove(this.powerstations[i]);
        }
        this.powerstations = [];
      }

      loading.close();
      VueSimpleAlert.fire({
        title: 'Done',
        type: 'success',
        timer: 5000,
        showConfirmButton: true,
      });
    },

    async updateSites(dataset, dateRange, metricName, { selectedSiteOption, metricLifetime }) {
      const loading = VueSimpleAlert.fire({
        title: 'Loading',
        text: 'Displaying metric sites...',
        type: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      this.updateViewerClock(dateRange.startDateStr, dateRange.endDateStr);

      const data = (await dataset.getData(dateRange, metricName));
      const sources = await dataset.getSources();

      this.Sites.deleteEntities(); // Delete all sites currently displayed in Cesium
      this.Sites.createEntities(data, sources, selectedSiteOption, metricLifetime);

      loading.close();
    },

    // Update the viewer with a generic dataset API
    async update(dataset, dateRange, metricName, displayOptions) {
      // Display loading prompt for the duration of this function
      const loading = VueSimpleAlert.fire({
        title: 'Loading',
        text: 'Downloading dataset...',
        type: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      const data = (await dataset.getData(dateRange, metricName));
      const metricSummary = await dataset.getMetricSummary(metricName);
      this.heatKeyTitle = metricSummary.name;
      const accumulate = (await dataset.getAccumulate(metricName)) ?? false;
      const sources = await dataset.getSources();

      if (data.length === 0) {
        loading.close();
        VueSimpleAlert.fire({
          title: 'Error',
          text: 'Failed to find any metrics in the Elastic database that match those requirements.',
          type: 'error',
        });
        return;
      }

      this.deSelect();
      this.stopClockAnimation();
      this.updateViewerClock(dateRange.startDateStr, dateRange.endDateStr);

      this.viewer.dataSources.removeAll(true);
      this.Heatmap.deleteHeatmap(); // Delete heatmap if it's currently displayed in Cesium

      for (let i = 0; i < this.routeConnectors.length; i++) {
        this.viewer.entities.remove(this.routeConnectors[i]);
      }

      // Remove temporary entities
      for (let i = 0; i < this.entities.length; i++) {
        this.viewer.entities.remove(this.entities[i]);
      }
      this.entities = [];

      this.colourMin = Number.POSITIVE_INFINITY;
      this.colourMax = Number.NEGATIVE_INFINITY;

      let successMessage = '';

      if (displayOptions.regionName === 'heatmap') {
        await this.Heatmap.createHeatmap(
          data,
          displayOptions,
        );

        successMessage = 'Heatmap loaded successfully!';
      } else {
        successMessage = await common.regions.process.updateWithRegion(
          this,
          sources,
          metricSummary,
          data,
          accumulate,
          displayOptions,
        );
      }

      loading.close();
      await this.updateSites(dataset, dateRange, metricName, displayOptions);
      VueSimpleAlert.fire({
        title: 'Done',
        text: successMessage,
        type: 'success',
        timer: 5000,
        showConfirmButton: true,
      });

      // Finally start the clock back up if the dates vary
      if (dateRange.startDateStr !== dateRange.endDateStr) this.startClockAnimation();
    },

    // TODO: Lots of repeating code could maybe put into one update function
    LEFT_CLICK(movement) {
      // Probably all of this should get moved into RoutePlanner and we should pass this event

      if (!this.planningRoute || this.viewer.selectedEntity) {
        return;
      }

      // Get the position of the click as a Cartesian3
      const { ellipsoid } = this.viewer.scene.globe;
      const position = this.viewer.camera.pickEllipsoid(movement.position, ellipsoid);

      // The "node" entity for the route, probably change to something that looks better
      const e = this.viewer.entities.add({
        position,
        name: `Route node ${this.routeEntities.length + 1}`,
        ellipse: {
          semiMinorAxis: 5,
          semiMajorAxis: 5,
          height: 0,
          material: this.Cesium.Color.BLACK.withAlpha(0.5),
          outline: true,
        },
      });

      // Add connecting lines using polyline entities
      if (this.routeEntities.length > 0) {
        const prevPosition = this.routeEntities[this.routeEntities.length - 1].position.getValue();
        const connector = this.viewer.entities.add({
          name: 'Red line on terrain',
          polyline: {
            positions: [prevPosition, position],
            width: 5,
            material: this.Cesium.Color.RED,
            clampToGround: true,
          },
        });

        this.routeConnectors.push(connector);
      }

      // Keeps track of the nodes and connectors so we can do stuff with them (not implemented)
      this.routeEntities.push(e);
    },
  },
  mounted() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log(`Auth event: ${event}`);
      switch (event) {
        case 'signIn':
          console.log(`signIn data: ${JSON.stringify(data)}`);
          this.signedIn = true;
          this.username = data.username;
          break;
        case 'signOut':
          this.signedIn = false;
          this.username = null;
          break;
        default:
          console.log(`auth default data: ${JSON.stringify(data)}`);
      }
    });
    common.ElasticClient.getInstance().setAWSAuth(Auth);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(`Username: ${user.username}`);
        console.log('Refreshing access and id tokens');
        Auth.currentSession()
          .then((data) => {
            console.log(data);
            this.username = user.username;
          })
          .catch((err) => console.log(`Error refreshing tokens: ${err}`));
        Auth.currentUserCredentials()
          .then(() => {
            this.signedIn = true;
          })
          .catch((err) => console.log('get current credentials error', err));
      })
      .catch((err) => {
        console.log('redirect here ...');
        console.log(`Auth.currentAuthenticatedUser(): ${err}`);
        Auth.federatedSignIn();
      });
  },
};
</script>

<style>

.sensorium-toolbar {
  position: absolute;
  width: 100%;
  top: 0em;
}

.sensorium-heatmap-key {
  position: absolute;
  bottom: 1.5em;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: auto;
  margin: 0 1em 1em 0;
}

.hovering-component {
  background: black;
  color: white;
  opacity: 0.75;
  transition: opacity 0.3s;
}

.hovering-component:hover {
  opacity: 1;
}
</style>
