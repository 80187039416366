<template>
  <div class="hovering-component">
    <div class="button-container">
      <button type="button" @click="startButtonClicked">Start</button>
      <button type="button" @click="stopButtonClicked">Stop</button>
      <button type="button" @click="clearButtonClicked">Clear (TODO)</button>
    </div>
  </div>
</template>

<script>
// Currently this just echos our choices back to Globe.vue using $emit, I think in the future
// it would make sense to store all the routing logic here and just pass up something for
// Globe to render

export default {
  name: 'RoutePlanner',
  methods: {
    startButtonClicked() {
      this.$emit('setPlanningRoute', true);
    },
    stopButtonClicked() {
      this.$emit('setPlanningRoute', false);
    },
    clearButtonClicked() {
      // TODO: This
    },
  },
};
</script>

<style scoped>
.hovering-component, .button-container {
  display: flex;
  align-items: center;
}

.hovering-component {
  flex-direction: column;
}

.hovering-component > p {
  margin: 0 0 0.5em 0;
}

.button-container > button {
  margin: 0 0.5em;
}
</style>
