<template>
  <div id="app">
    <Globe />
  </div>
</template>

<script>
import { components } from 'aws-amplify-vue';
import Globe from './components/Globe.vue';

export default {
  name: 'App',
  components: {
    Globe,
    ...components,
  },
  created() {
    document.title = 'Roke Sensorium';
  },
};
</script>

<style>
* {
  font-family: "Gill Sans MT", Calibri;
}

html, body, #app, .viewer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
